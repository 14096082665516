import {
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterConsentManagerStatus,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkInviteFriendsResult
} from "./FxIdSdkBaseAdapter";
import OpenApiClient, { openApiConfig } from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData,
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayGamePublicConfigResponse,
	FxIdWebFeaturesPlayPublicDataBaseAdvertisement,
	FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
} from "../Api/gen";
import { sharingHandlersStore } from "../Stores/ShareHandlersStore";
import i18next from "i18next";
import { userStore } from "../Stores";
import { getQueriedProfileData } from "../Api/Queries";
import { ok, telegram, vk } from "vanilla-sharing";
import { appStore } from "../Stores/AppStore";
import { deferred } from "./FxIdSdkUtils";
import { useOs } from "@mantine/hooks";
import { openIframeModal, openLinkShareModal } from "../Components/Modals";
import { closeModal } from "@mantine/modals";
import { openStripeCheckoutModal } from "../Components/Modals/StripeCheckoutModal";
import useScript from "../Utils/Hooks/useScript";

export class FxIdSdkAdapterForFxId extends FxIdSdkBaseAdapter {
	private buyProductResolve?: (data: any) => void;
	private buyProductReject?: (data: any) => void;
	private paymentIsSuccessful = false;
	private lastPaymentModalId?: string;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayGamePublicConfigResponse
	) {
		super(exportedSdk);
	}

	private IsMobileXsollaMode(): boolean {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const os = useOs();
		return os === "android" || os === "ios";
	}

	async RegisterShareHandlers(): Promise<void> {
		const { enableLinkCopy, enableShareOk, enableShareTelegram, enableShareVk } = sharingHandlersStore.getState();
		const { enableWebPlayLogin } = appStore.getState();
		const data = { url: window.location, title: i18next.t(`seo.${this.game}.title`) };
		enableShareVk(() => vk(data));
		enableShareOk(() => ok(data));
		enableShareTelegram(() => telegram(data));
		enableLinkCopy();

		/*
		 * TODO: Или переместить в более логичное место или переименовать метод, чтобы можно было подобные подвязыки
		 * включать туn
		 */
		enableWebPlayLogin();

		const profileData = await getQueriedProfileData();

		const userDisplayName = profileData.Name ?? profileData.Email ?? `#${profileData.AccountId}`;

		userStore.getState().updateUserId(String(profileData.AccountId));
		userStore.getState().updateDisplayName(userDisplayName);

		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const profileData = await getQueriedProfileData();

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed
		});

		log.info("Received result from server: %o", createPaymentResult);
		return this.StartPayment(createPaymentResult);
	}

	protected StartPayment(createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult) {
		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
		this.buyProductResolve = (data: any) => {
			log.info("Buy product resolved %o", data);
			resolve({ ...data, transactionId: createPaymentResult.TransactionId });
		};
		this.buyProductReject = (data: any) => {
			log.info("Buy product rejected %o", data);
			reject(new Error(data));
		};

		// Предполагаем что всегда есть на FxId

		const xsollaPaymentResult = createPaymentResult.Xsolla;
		const yookassaPaymentResult = createPaymentResult.Yookassa;
		const stripePaymentResult = createPaymentResult.Stripe;
		const moneyMailRuResult = createPaymentResult.MoneyMailRu;
		if (xsollaPaymentResult != null) {
			this.StartXsollaPayment(xsollaPaymentResult, createPaymentResult);
		} else if (yookassaPaymentResult != null) {
			this.StartYookassaPayment(yookassaPaymentResult, createPaymentResult);
		} else if (stripePaymentResult != null) {
			void this.StartStripePayment(stripePaymentResult, createPaymentResult);
		} else if (moneyMailRuResult != null) {
			this.StartMoneyMailRuPayment(moneyMailRuResult, createPaymentResult);
		} else {
			throw new Error("Unsupported payment method for FxId web");
		}

		return promise;
	}

	private StartYookassaPayment(
		yookassaPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData,
		createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
	) {
		if (yookassaPaymentResult.Redirect && yookassaPaymentResult.ConfirmationUrl) {
			window.open(yookassaPaymentResult.ConfirmationUrl, "_blank", "noopener,noreferrer");
			this.buyProductResolve?.call(this, "[YOOKASSA] Yookassa configured with redirect. Returning immediately");
			return;
		}

		if (yookassaPaymentResult.ConfirmationToken) {
			const confirmation_token = yookassaPaymentResult.ConfirmationToken;
			if (!window.YooMoneyCheckoutWidget) {
				this.buyProductReject?.call(this, "[YOOKASSA] No Yoomoney widget in window!");
				return;
			}

			const checkout = new window.YooMoneyCheckoutWidget({
				confirmation_token,
				error_callback: log.error,
				customization: {
					modal: true
				}
			});
			checkout.on("success", () => {
				log.info("[YOOKASSA] Yookassa payment successful!");
				this.buyProductResolve?.call(this, { transactionId: createPaymentResult.TransactionId });
			});
			checkout.on("complete", () => checkout.destroy());
			checkout.on("fail", () => {
				log.info("[YOOKASSA] Payment failed for some reason!");
				this.buyProductReject?.call(this, "Payment failed for some reason!");
			});
			checkout.on("modal_close", () => {
				log.info("[YOOKASSA] Widget closed before payment completed");
				this.buyProductReject?.call(this, "Widget closed before payment completed");
			});

			void checkout.render();
			return;
		}

		throw new Error("[YOOKASSA] No data for Yookassa payment");
	}

	private StartXsollaPayment(
		xsollaData: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData,
		createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
	) {
		this.paymentIsSuccessful = false;

		if (!window.XPayStationWidget) throw new Error("No Xsolla widget in window!");

		// https://github.com/xsolla/paystation-embed

		// На мобилках xsolla виджет работает не правильно, поэтому там открывается айфрейм с платежным урлом
		if (this.IsMobileXsollaMode()) {
			log.info("[XSOLLA] Opening Xsolla in iframe with url:", xsollaData.ConfirmationUrl);
			this.lastPaymentModalId = new Date().valueOf().toString();

			openIframeModal({
				url: xsollaData.ConfirmationUrl,
				onClose: () => postMessage({ cmd: "FxIdSdkModalClosedEvent", modalId: this.lastPaymentModalId }, "*"),
				fullScreen: true
			});
		} else {
			log.info("[XSOLLA] Initializing Xsolla widget");
			// https://github.com/xsolla/paystation-embed#widget-options
			const xsollaOptions: any = {
				access_token: xsollaData.PaymentToken,
				sandbox: xsollaData.Sandbox
			};

			if (this.IsMobileXsollaMode()) {
				xsollaOptions.childWindow = { target: "_parent" };
			}

			window.XPayStationWidget.init(xsollaOptions);

			log.info("[XSOLLA] Opening Xsolla widget with options: %o", xsollaOptions);
			window.XPayStationWidget.open();
		}
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const profileData = await getQueriedProfileData();
		return {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId,
			userId: profileData.AccountId.toString(),
			capabilities: []
		};
	}

	async Initialize(): Promise<void> {
		if (this.config.Store.XsollaEnabled) {
			// https://github.com/xsolla/paystation-embed
			// Не ждем
			void new Promise<void>((resolve) => {
				const s = document.createElement("script");
				s.type = "text/javascript";
				s.src = `https://cdn.xsolla.net/embed/paystation/1.2.9/widget.min.js`;
				s.async = true;
				s.addEventListener(
					"load",
					(e) => {
						log.info("Xsolla widget loaded");
						this.StartListenForXsollaEvents();
						resolve();
					},
					false
				);
				const head = document.getElementsByTagName("head")[0];
				head.appendChild(s);

				log.info("Xsolla script added");
			});
		}

		if (this.config.Store.YookassaEnabled) {
			void new Promise<void>((resolve) => {
				const s = document.createElement("script");
				s.type = "text/javascript";
				s.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
				s.async = true;
				s.addEventListener("load", () => {
					log.info("Yookassa widget loaded");
					resolve();
				});
				const head = document.getElementsByTagName("head")[0];
				head.appendChild(s);

				log.info("Yookassa script added");
			});
		}

		void this.InitializeGoogleAds(this.config.Advertisement);

		this.StartListenToMoneyMailRuEvents();

		return Promise.resolve();
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	AdsIsVideoReady(): Promise<boolean> {
		log.info("[VIDEO ADS] Video Ads status requested. Ads ready status:", window.googleVideoAdsReady);
		return Promise.resolve(window.googleVideoAdsReady ?? false);
	}

	async AdsShowVideo(): Promise<void> {
		log.info("[VIDEO ADS] Ad requested");
		if (typeof window.adBreak === "undefined") {
			log.warn("[VIDEO ADS] adBreak function not yet ready");
			return;
		}
		if (!(await this.AdsIsVideoReady())) {
			log.warn("[VIDEO ADS] Ad requested when ads not yet ready");
			return;
		}
		window.adBreak({
			type: "reward",
			name: "unknown",
			beforeAd: () => {
				log.info("[VIDEO ADS] Prepare for the ad. Mute and pause the game flow");
			},
			afterAd: () => {
				log.info("[VIDEO ADS] Resume the game and re-enable sound");
			},
			// Prepare for the ad. Mute and pause the game flow
			beforeReward: (showAdFn: () => void) => {
				log.info("[VIDEO ADS] Show reward prompt (call showAdFn() if clicked)");
				showAdFn();
			},
			adDismissed: () => {
				log.info("[VIDEO ADS] Player dismissed the ad before completion");
				window.FxIdSdk!.DispatchAdsSkipped();
			},
			adViewed: () => {
				log.info("[VIDEO ADS] Ad was viewed and closed");
				window.FxIdSdk!.DispatchAdsFinished();
			},
			adBreakDone: (placementInfo) => {
				log.info(
					"[VIDEO ADS] Always called (if provided) even if an ad didn't show. PlacementInfo: %o",
					placementInfo
				);

				// Dismissed обрабатывается выше. Все остальное - ошибки
				if (placementInfo.breakStatus != "viewed" && placementInfo.breakStatus != "dismissed") {
					window.FxIdSdk!.DispatchAdsFailed();
				}
			}
		});
	}

	private StartListenForXsollaEvents() {
		const sendResult = (args: any[]) => {
			if (this.paymentIsSuccessful) {
				log.info("[XSOLLA] Widget closed after payment successfully completed. All is good", args);
				this.buyProductResolve?.call(this, {});
			} else {
				log.info("[XSOLLA] Widget closed before payment completed", args);
				this.buyProductReject?.call(this, "Payment widget closed before payment completed!");
			}
		};

		const sendResultFromModalClosed = (args: any[]) => {
			if (this.paymentIsSuccessful) {
				log.info("Modal closed after payment successfully completed. All is good", args);
				this.buyProductResolve?.call(this, {});
			} else {
				log.info("Modal closed before payment completed", args);
				this.buyProductReject?.call(this, "Payment widget closed before payment completed!");
			}
		};

		// https://developers.xsolla.com/doc/pay-station/features/paystation-analytics/#pay_station_features_analytics_ps_events
		window.addEventListener("message", (_event) => {
			if (
				_event.origin === "https://secure.xsolla.com" ||
				_event.origin === "https://sandbox-secure.xsolla.com"
			) {
				log.info("Event from Xsolla: %o", _event);

				const event = JSON.parse(_event.data);

				if (event.command === "return") {
					// Clicking the Return to Store/Start again button.
					sendResult(event);
					if (!this.IsMobileXsollaMode()) {
						window.XPayStationWidget!.close();
					}
					closeModal("iframe");
				} else if (event.command === "status") {
					if (event.data.paymentInfo.status === "done") {
						// Changing the order status to done. The event is sent from the payment status page if an order was created via one of the following API calls
						this.paymentIsSuccessful = true;
						sendResult(event);

						if (!this.IsMobileXsollaMode()) {
							window.XPayStationWidget!.close();
						}

						closeModal("iframe");
					} else {
						log.info("Status: %o", event.data.paymentInfo.status);
					}
				}
			}

			if (typeof _event.data === "object") {
				if (_event.data.cmd === "FxIdSdkModalClosedEvent") {
					log.info("Modal closed");

					if (_event.data.modalId === this.lastPaymentModalId) {
						log.info("Payment modal closed");
						sendResultFromModalClosed(_event.data);
					}
				}
			}
		});

		// В мобильном варианте, когда иксола открывается в айфрейме, может и не быть виджета

		if (window.XPayStationWidget == null) {
			log.error("Can't listen for xsolla events - window.XPayStationWidget is null");
			return;
		}

		window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.STATUS_DONE, (...args) => {
			log.info("[XSOLLA] Xsolla payment successful!", args);
			this.paymentIsSuccessful = true;
			// Тут просто запоминается состояние что платеж завершен
			// сам эвент отправится в CLOSE
			// т.е. в этот момент платежка еще открыта и если слать сразу resolve
			// то игры будут показывать анимацию под платежкой
			// resolve({ transactionId: createPaymentResult.TransactionId });
		});

		window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, (...args) => {
			log.info("[XSOLLA] 'close' event");
			sendResult(args);
		});
		window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE_WIDGET, (...args) => {
			log.info("[XSOLLA] 'close-widget' event");
			sendResult(args);
		});

		window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.RETURN, (...args) => {
			log.info("[XSOLLA] 'return' event");
			sendResult(args);
		});
	}

	async InviteFriends(): Promise<IFxIdSdkInviteFriendsResult> {
		const profileData = await getQueriedProfileData();
		// HACK: У нас нет способа забрать из OpenApiClient точный путь до эндпоинта с редиректом, поэтому собираем его
		// руками и надеемся, что все не сломается
		const redirectUrl = new URL(`${openApiConfig.basePath}/api/v1/go`);
		redirectUrl.searchParams.append("fxref", String(profileData.AccountId));
		redirectUrl.searchParams.append("url", window.location.toString());
		openLinkShareModal({ link: redirectUrl.toString() });
		return { friends: [] };
	}

	private async StartStripePayment(
		stripePaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData,
		createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
	) {
		this.paymentIsSuccessful = false;
		let paymentCompleted = false;

		const stripeOptions = createPaymentResult.Stripe;
		if (stripeOptions == null) throw new Error("Stripe not in result");
		// openIframeModal({ url: stripePaymentResult.SessionUrl });
		// window.open(stripePaymentResult.SessionUrl, "_blank");
		const profileData = await getQueriedProfileData();
		openStripeCheckoutModal({
			paymentSecret: stripePaymentResult.PaymentIntentClientSecret,
			publishableKey: stripePaymentResult.PublishableKey,
			returnUrl: window.location.href,
			email: profileData.Email,
			onClose: () => {
				postMessage({ cmd: "FxIdSdkModalClosedEventStripe", modalId: this.lastPaymentModalId }, "*");

				if (!paymentCompleted) {
					if (this.paymentIsSuccessful) {
						this.buyProductResolve?.call(this, {});
					} else {
						this.buyProductReject?.call(this, "Unknown error");
					}
				}
			},
			onCompleted: () => {
				this.paymentIsSuccessful = true;
				paymentCompleted = true;
				this.buyProductResolve?.call(this, {});
			},
			onError: (message) => {
				paymentCompleted = true;
				this.buyProductReject?.call(this, message);
			}
		});
		// this.buyProductResolve?.call(this, {});
	}

	private StartListenToMoneyMailRuEvents() {
		const sendResultFromModalClosed = (eventData: any) => {
			if (eventData.action === "closeWindow" || eventData.action === "paySuccess") {
				this.buyProductResolve?.call(this, {});
			} else {
				this.buyProductReject?.call(this, {});
			}
		};

		window.addEventListener("message", (_event) => {
			if (
				_event.origin === "https://pay.my.com" ||
				_event.origin === "https://pw.money.mail.ru" ||
				_event.origin === "https://pay.my.games"
			) {
				log.info("Event from Money.MailRu: %o", _event);

				const event = JSON.parse(_event.data);

				// Тут эвенты от ДМР и от нового MoneyMail.Ru
				if (
					event.action === "closeWindow" ||
					event.action === "refreshWindow" ||
					event.action === "paySuccess" ||
					event.action === "payError"
				) {
					// Clicking the Return to Store/Start again button.
					sendResultFromModalClosed(event);
					closeModal("iframe");
				}
			}

			if (typeof _event.data === "object") {
				if (_event.data.cmd === "FxIdSdkModalClosedEventMailRu") {
					log.info("MailRu Payment modal closed");
					sendResultFromModalClosed(_event.data);
				}
			}
		});
	}

	private StartMoneyMailRuPayment(
		moneyMailRuResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData,
		createPaymentResult: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
	) {
		openIframeModal({
			url: moneyMailRuResult.PaymentUri,
			onClose: () => postMessage({ cmd: "FxIdSdkModalClosedEventMailRu", modalId: this.lastPaymentModalId }, "*"),
			fullScreen: true
		});
	}

	private SetGoogleVideoAdsRead(): void {
		window.googleVideoAdsReady = true;
		window.FxIdSdk!.DispatchAdsVideoAvailable();
	}

	private async InitializeGoogleAds(advOptions: FxIdWebFeaturesPlayPublicDataBaseAdvertisement | null | undefined) {
		window.setGoogleVideoAdsReady = this.SetGoogleVideoAdsRead.bind(this);

		if (advOptions?.GoogleAdvId == null) {
			log.info("No google ads id");
			return;
		}

		await new Promise<void>((resolve, reject) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${advOptions?.GoogleAdvId}`;
			s.setAttribute("data-ad-frequency-hint", "30s");
			s.setAttribute("crossorigin", "anonymous");
			s.async = true;
			s.addEventListener(
				"load",
				(e) => {
					log.info("Google ads loaded");
					window.adBreak = function (o: any) {
						log.info("[VIDEO ADS] Pushing adBreak: %o", o);
						window.adsbygoogle?.push(o);
					};
					window.adConfig = function (o: any) {
						log.info("[VIDEO ADS] Pushing adConfig: %o", o);
						window.adsbygoogle?.push(o);
					};
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Google ads added");
		});

		if (typeof window.adConfig !== "undefined") {
			log.info("Requesting ads from google");
			window.adConfig({
				preloadAdBreaks: "on",
				sound: "on",
				onReady: () => {
					log.info("[VIDEO ADS] Google Video Ads ready");
					window.googleVideoAdsReady = true;
				}
			});
		} else {
			log.info("window.adConfig not initialized");
		}
	}
}
