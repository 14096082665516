/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationType } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationType';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationTypeFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationTypeFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationTypeToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationType}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    AuthenticationType: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    SteamLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    EgsLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest
     */
    GoogleLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AuthenticationType" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationTypeFromJSON(json['AuthenticationType']),
        'Game': json['Game'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'SessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'SteamLogin': !exists(json, 'SteamLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSON(json['SteamLogin']),
        'EgsLogin': !exists(json, 'EgsLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLoginFromJSON(json['EgsLogin']),
        'GoogleLogin': !exists(json, 'GoogleLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSON(json['GoogleLogin']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticationTypeToJSON(value.AuthenticationType),
        'Game': value.Game,
        'Locale': value.Locale,
        'SessionId': value.SessionId,
        'SteamLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginToJSON(value.SteamLogin),
        'EgsLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoEgsLoginToJSON(value.EgsLogin),
        'GoogleLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginToJSON(value.GoogleLogin),
    };
}

