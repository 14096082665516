/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoGoogleLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdGooglePlayLoginData);
    }

    return {};
}

